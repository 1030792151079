import { useState } from 'react';

import Register from './Register';
import Login from './Login';

interface SignUpSignInProps {
  onClose: () => void;
}

const SignUpSignIn = ({ onClose }: SignUpSignInProps) => {
  const [showLogin, setShowLogin] = useState(false);

  return showLogin ? (
    <Login setShowLogin={setShowLogin} onClose={onClose} />
  ) : (
    <Register
      setShowLogin={setShowLogin}
      showLogin={showLogin}
      onClose={onClose}
    />
  );
};

export default SignUpSignIn;
