import { useState } from 'react';
import {
  Box,
  Container,
  VStack,
  Heading,
  Image,
  Input,
  Button,
  Spinner,
  Spacer,
  HStack,
} from '@chakra-ui/react';
import { v4 } from 'uuid';
import ScrollIntoViewIfNeeded from 'react-scroll-into-view-if-needed';

import useAnalyticsEventTracker from '../hooks/useAnalyticsEventTracker';
import completion from '../client/backendClient';
import CompanyIdeaCard from './CompanyIdeaCard';
import NavBar from './NavBar';

const GenerateIdea = () => {
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [output, setOutput] = useState('');

  const gaEventTracker = useAnalyticsEventTracker();

  const generateIdea = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setIsLoading(true);
    const response = await completion({
      context: {
        stepIndex: 0,
        chat: [
          {
            prompt: {
              id: 'IDEA_GENERATOR',
              value: '',
              type: 'text',
            },
            userInput: inputValue,
          },
        ],
      },
      type: 'landing-idea-generator',
      sessionId: v4(),
    });
    setIsLoading(false);
    setOutput(response.slides[0].text.value);
    gaEventTracker('GENERATE-IDEA-PAGE_CLICK-GENERATE_IDEA');
  };

  const showIdea = !isLoading && output;

  return (
    <Box
      minW="100vw"
      minH="100Vh"
      bgGradient="linear(to-r, gray.300, yellow.400, pink.200)"
    >
      <NavBar />
      <Container maxW="container.lg" pb={10}>
        <VStack
          spacing={6}
          alignItems="center"
          py={12}
          as="form"
          onSubmit={generateIdea}
        >
          <Heading as="h1" size="2xl">
            Startup Idea Generator
          </Heading>
          <Image
            src="/generate.png"
            alt="Startup idea generator"
            width={{ base: '50%', md: '30%' }}
            height="auto"
            objectFit="cover"
            borderRadius="lg"
          />
          <HStack spacing={2} w="60%">
            <Input
              placeholder="What industry do you want to disrupt? e.g: 'travel'"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              maxW="lg"
              width="100%"
            />
            <Spacer />
            <Button
              colorScheme="teal"
              onClick={generateIdea}
              disabled={!inputValue || isLoading}
              px={8}
            >
              Generate Idea
            </Button>
          </HStack>
        </VStack>

        {isLoading && (
          <Box
            bg="whiteAlpha.600"
            color="black"
            borderRadius="lg"
            m={{ sm: 4, md: 16, lg: 10 }}
            p={{ sm: 5, md: 5, lg: 16 }}
            display="flex"
            justifyContent="space-between"
          >
            <Heading fontWeight={400}>Generating awesome idea...</Heading>
            <br />
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="black"
              size="xl"
            />
          </Box>
        )}

        {showIdea && (
          <ScrollIntoViewIfNeeded>
            <CompanyIdeaCard output={output} />
          </ScrollIntoViewIfNeeded>
        )}
      </Container>
    </Box>
  );
};

export default GenerateIdea;
