import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Link,
  Box,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { Link as ReactRouterLink } from 'react-router-dom';

interface MobileMenuProps {
  onGeneratePitch: () => void;
  handleScrollToggle: () => void;
}

const MobileMenu = ({
  onGeneratePitch,
  handleScrollToggle,
}: MobileMenuProps) => (
  <Box display={{ base: 'flex', md: 'none' }}>
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<HamburgerIcon />}
        variant="outline"
      />
      <MenuList>
        <MenuItem onClick={onGeneratePitch}>Generate Pitch</MenuItem>
        <MenuItem as={ReactRouterLink} to="/generate-idea">
          Generate Idea
        </MenuItem>
        <MenuItem onClick={handleScrollToggle}>
          <Link>Contact us!</Link>
        </MenuItem>
      </MenuList>
    </Menu>
  </Box>
);

export default MobileMenu;
