import {
  Box,
  Heading,
  Container,
  Text,
  Stack,
  Input,
  Flex,
  Button,
  AspectRatio,
} from '@chakra-ui/react';

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';

import { auth } from '../firebase';
import useAnalyticsEventTracker from '../hooks/useAnalyticsEventTracker';
import { AuthParams } from '../types/AuthParams';

interface NewHeroProps {
  onOpen: (params: AuthParams) => void;
}

const NewHero = ({ onOpen }: NewHeroProps) => {
  const [title, setTitle] = useState('');
  const [user] = useAuthState(auth);

  const navigate = useNavigate();

  const handleChange = (event: React.FormEvent<HTMLInputElement>): void =>
    setTitle(event.currentTarget.value);

  const gaEventTracker = useAnalyticsEventTracker();

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    gaEventTracker('HOME-PAGE_GET-STARTED_CTA_ACTION');
    if (user) {
      navigate('/general-purpose', { state: title });
    } else {
      onOpen({ next: '/general-purpose', state: title });
    }
  };

  return (
    <Container maxW="8xl">
      <Stack
        minWidth="250px"
        as={Box}
        textAlign="center"
        spacing={{ base: 8, md: 8 }}
        pt={10}
      >
        <Heading
          fontWeight={600}
          fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
          lineHeight="110%"
        >
          Craft compelling decks in minutes with AI
        </Heading>
        <Box
          display="flex"
          alignItems="space-between"
          flexDirection="row"
          justifyContent="center"
        >
          <Text display="block" width="60%">
            You can forget the frustration of creating presentations from
            scratch. Answer a few questions and our powerful AI builder will
            create your deck for you in seconds.
          </Text>
        </Box>
      </Stack>

      <Container maxWidth="2xl">
        <Flex
          direction={{ base: 'column', md: 'row' }}
          as="form"
          onSubmit={onSubmit}
          pt={10}
        >
          <Input
            w="100%"
            borderWidth="2px"
            borderColor="gray.600"
            roundedRight={{ base: 'md', md: '0' }}
            variant="solid"
            required
            placeholder="Enter your presentation title"
            value={title}
            onChange={handleChange}
          />
          <Box w={{ base: '100%', md: '50%' }}>
            <Button
              w="100%"
              mt={{ base: 2, md: 0 }}
              borderWidth="2px"
              borderColor="gray.600"
              colorScheme="blackAlpha"
              roundedLeft={{ base: 'md', md: '0' }}
              bg="black"
              onClick={onSubmit}
            >
              Get Started
            </Button>
          </Box>
        </Flex>
        <Box display="flex" justifyContent="center" pt={4}>
          <Text color="gray.500">Powered by OpenAI’s GPT-3 💚</Text>
        </Box>
      </Container>

      {/* PENDING NEW VIDEO */}
      <Container maxWidth="4xl">
        <Stack
          as={Box}
          pt={10}
          h="100%"
          width="100%"
          minWidth="250px"
          minHeight="200px"
          display="flex"
          alignItems="center"
        >
          <AspectRatio minW="60%" ratio={1} maxH="400px">
            <iframe
              title="naruto"
              src="https://www.loom.com/embed/dbf10e1ac71747ff82245f8b63d4909a"
              allowFullScreen
              style={{
                top: 0,
                left: 0,
                width: '100%',
                height: '80%',
              }}
            />
          </AspectRatio>
        </Stack>
      </Container>
    </Container>
  );
};

export default NewHero;
