import React from 'react';
import { Box, Heading, Text, SimpleGrid } from '@chakra-ui/react';

const Benefits = () => {
  const benefits = [
    {
      id: 1,
      title: 'Generate Great Decks in Seconds',
      description:
        'Our platform anticipates your needs and provides you with a stunning deck in just seconds. No more spending hours creating a presentation from scratch – the process is simplified, fast and you know you’ll have a great deck.',
    },
    {
      id: 2,
      title: 'Create an Impactful Narrative, No matter your purpose',
      description:
        "Our presentation builder helps you create an impactful narrative, whether you're doing a sales pitch, teaching a class, or simply sharing an idea with your team. With our tool, you can focus on your message and let the AI anticipate what you need.",
    },
    {
      id: 4,
      title: 'Iterate with Ease Thanks to Your AI Helper',
      description:
        'Power Mode AI takes the pain out of editing and revising your deck, you can make changes quickly and easily without worrying about time-consuming edits. Just clarify what you want changed and it’ll give you options.',
    },
    {
      id: 5,
      title:
        "No More Last-Minute Panic Attacks When You Realize the Deck Isn't Ready",
      description:
        'You’ll never have to worry about cramming to finish a deck last minute, when it takes you all of 90 seconds to have one that’s 90% there and edits away from being ready. Power Mode takes you from Oh Shit! to ready to present faster than it takes to get your coffee at Starbucks.',
    },
  ];

  return (
    <Box maxWidth="1200px" mx="auto" mt={8} p={8}>
      <Heading textAlign="center" mb={8}>
        Benefits
      </Heading>
      <SimpleGrid columns={[1, 1, 2, 2]} spacing={8}>
        {benefits.map((benefit) => (
          <Box
            key={benefit.id}
            borderRadius="lg"
            backgroundColor="blackAlpha.200"
            p={4}
          >
            <Heading size="md" mb={2}>
              {benefit.title}
            </Heading>
            <Text>{benefit.description}</Text>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default Benefits;
