import { extendTheme } from '@chakra-ui/react';
import { Button } from './button';

// curtom color gen for colorscheme: https://themera.vercel.app/

const theme = extendTheme({
  colors: {
    powerPurple: {
      '50': '#EEE7FE',
      '100': '#CEBBFC',
      '200': '#AF8FFA',
      '300': '#9063F8',
      '400': '#7137F5',
      '500': '#520CF3', // background
      '600': '#4109C3',
      '700': '#310792', // hover active
      '800': '#210561',
      '900': '#100231',
    },
    sectionIndicator: 'rgba(44, 58, 66, 0.65)',
    sectionIndicatorCompleted: 'rgba(49, 151, 96, 0.81)',
  },
  fonts: {
    logo: `'Doppio One', sans-serif`,
    heading: `'Doppio One', sans-serif`,
    body: `'Doppio One', sans-serif`,
  },
  components: {
    Button,
  },
});

export default theme;
