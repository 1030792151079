import { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import {
  useToast,
  Flex,
  Input,
  Button,
  Box,
  Link,
  Icon,
} from '@chakra-ui/react';
import { FaGoogle } from 'react-icons/fa';

import {
  auth,
  logInWithEmailAndPassword,
  signInWithGoogle,
} from '../../firebase';
import { getErrorMessage } from '../../utils';

interface LoginProps {
  setShowLogin: (value: boolean) => void;
  onClose: () => void;
}

const Login = ({ setShowLogin, onClose }: LoginProps) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [user, loading] = useAuthState(auth);
  const toast = useToast();

  const handleLogin = async () => {
    try {
      await logInWithEmailAndPassword(email, password);
      toast({
        title: 'Sign in Success',
        description: "You've signed in successfully",
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (err) {
      toast({
        title: 'Small issue 😐',
        description: `${getErrorMessage(err)}`,
        status: 'warning',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (loading) {
      // TODO:  trigger a loading state
    }
    if (user) {
      toast({
        title: 'Login successful',
        description: "You're now logged in",
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      onClose();
    }
    // eslint-disable-next-line
  }, [user, loading]);

  return (
    <Flex w="100%" wrap="wrap" gap={2}>
      <Input
        type="text"
        className="login__textBox"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="E-mail Address"
      />
      <Input
        type="password"
        className="login__textBox"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
      />
      <Button
        variant="solid"
        size="md"
        colorScheme="blackAlpha"
        bg="black"
        m="2px"
        onClick={handleLogin}
      >
        Login
      </Button>
      <Button
        variant="outline"
        size="md"
        colorScheme="blackAlpha"
        m="2px"
        color="black"
        onClick={signInWithGoogle}
      >
        <Icon as={FaGoogle} mr={2} />
        Login with Google
      </Button>
      {/* <Box><Link to="/reset">Forgot Password</Link></Box> TODO: Implement forgot password functionality */}
      <Box>
        Don't have an account?{' '}
        <Link color="teal.500" onClick={() => setShowLogin(false)}>
          Register now
        </Link>
      </Box>
    </Flex>
  );
};

export default Login;
