import { ListItem, UnorderedList } from '@chakra-ui/react';

interface Props {
  content: string[];
}

export const BulletPointsContent: React.FC<Props> = ({ content }) => (
  <UnorderedList>
    {content.map((c, index) => (
      <ListItem key={index}>{c}</ListItem>
    ))}
  </UnorderedList>
);
