import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import SignUpSignIn from './SignUpSignIn';

interface AuthModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AuthModal = ({ isOpen, onClose }: AuthModalProps) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Sign up or sign in 🚀</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <SignUpSignIn onClose={onClose} />
      </ModalBody>
      <ModalFooter>
        <Button colorScheme="blackAlpha" mr={3} onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

export default AuthModal;
