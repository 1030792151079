const Button = {
  variants: {
    sectionIndicator: {
      bg: 'sectionIndicator',
      borderRadius: 24,
      color: 'white',
      px: 9,
      py: 6,
    },
    sectionIndicatorCompleted: {
      bg: 'sectionIndicatorCompleted',
      borderRadius: 24,
      color: 'white',
      px: 9,
      py: 6,
    },
  },
};

export { Button };
