import { Box } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../firebase';
import { AuthParams } from '../../types/AuthParams';

import useAnalyticsEventTracker from '../../hooks/useAnalyticsEventTracker';
import DesktopMenu from './DesktopMenu';
import MobileMenu from './MobileMenu';

interface MenuProps {
  onOpen: (params: AuthParams) => void;
  handleScrollToggle: () => void;
}

const Menu = ({ onOpen, handleScrollToggle }: MenuProps) => {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);

  const gaEventTracker = useAnalyticsEventTracker();

  const onGeneratePitch = () => {
    gaEventTracker('HOME-PAGE_GET-GENERATE_PITCH');

    if (user) {
      navigate('/start');
    } else {
      onOpen({ next: '/start' });
    }
  };

  return (
    <Box>
      <DesktopMenu
        onGeneratePitch={onGeneratePitch}
        handleScrollToggle={handleScrollToggle}
      />
      <MobileMenu
        onGeneratePitch={onGeneratePitch}
        handleScrollToggle={handleScrollToggle}
      />
    </Box>
  );
};

export default Menu;
