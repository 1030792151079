import ReactGA from 'react-ga4';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase';

const useAnalyticsEventTracker = (category = 'Blog category') => {
  useAuthState(auth, {
    onUserChanged: (user) =>
      new Promise((resolve) => {
        if (user) {
          ReactGA.set({ userId: user.uid });
        }

        resolve();
      }),
  });

  const eventTracker = (action = 'test action', label = 'test label') => {
    ReactGA.event({ category, action, label });
  };
  return eventTracker;
};
export default useAnalyticsEventTracker;
