import {
  Button,
  HStack,
  Spinner,
  Flex,
  Text,
  VStack,
  Box,
} from '@chakra-ui/react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from '@chakra-ui/alert';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { parse } from 'best-effort-json-parser';
import SlideGenHeader from '../deckCreation/components/SlideGenHeader';
import { SlidePreview } from './GeneralSlidePreview/GeneralSlidePreview';
import { Topic } from '../../types/Topic';
import MobileWarning from '../MobileWarning';
import { downloadSlides } from './downloadSlides/downloadSlides';
import { Slide } from '../../types/Slide';
import { SlideType } from '../../types/SlideType';
import useAnalyticsEventTracker from '../../hooks/useAnalyticsEventTracker';
import {
  auth,
  incrementCustomDeckNewSlides,
  incrementCustomTotalGenerations,
} from '../../firebase';
import { PowerModeUser } from '../../types/PowerModeUser';
import { fetchUser } from './fetchUser/fetchUser';
import { getConfig } from '../../helpers/config';
import { PrimaryPromptForm } from './PrimaryPromptForm/PrimaryPromptForm';
import { PresentationStructureForm } from './PresentationStructureForm/PresentationStructureForm';

export const enum Status {
  IDLE = 'IDLE',
  GENERATING_STRUCTURE = 'GENERATING_STRUCTURE',
  GENERATING_SLIDES = 'GENERATING_SLIDES',
  ERROR = 'ERROR',
}

export const getLoadingMessage = (status: Status) => {
  const loadingMessage = {
    [Status.IDLE]: '',
    [Status.GENERATING_STRUCTURE]: 'Generating structure...',
    [Status.GENERATING_SLIDES]: 'Bringing up the slides...',
    [Status.ERROR]: '',
  };

  return loadingMessage[status];
};

const showLoadingMessage = (status: Status) => (
  <HStack justifyContent="center">
    <Spinner color="#fff" />
    <Text fontSize="2xl" color="#fff">
      {getLoadingMessage(status)}
    </Text>
  </HStack>
);

export const isLoadingStatus = (status: Status) =>
  [Status.GENERATING_STRUCTURE, Status.GENERATING_SLIDES].includes(status);

export const MAX_CHARACTER_COUNT = 500;

const GradientContainer = ({ children }: any) => (
  <VStack
    minH={0}
    width="100%"
    height="100%"
    maxH="100%"
    bgGradient="linear(to-tr, #620049, #56004b, #49004c, #3b004d, #29004e, #201057, #141b5f, #002565, #00396f, #00496b, #00555f, #026052)"
    overflow="hidden"
  >
    {children}
  </VStack>
);

const parsePartialSlide = (slide: Partial<Slide>) => {
  const type =
    slide.type === SlideType.Text || slide.type === SlideType.BulletPoints
      ? slide.type
      : SlideType.Text;
  const content =
    type === SlideType.BulletPoints && !slide.content
      ? ['']
      : slide.content || '';

  const parsedSlide = {
    title: slide.title || '',
    type,
    content,
  };

  return parsedSlide;
};

const getEventLabel = (title: string, topic: string) =>
  JSON.stringify({ title, topic });

const GeneralPurposePoc: React.FC = () => {
  const [furtherContext, setFurtherContext] = useState<string>('');
  const characterCount = furtherContext.length;

  const [slides, setSlides] = useState<Slide[] | null>(null);

  const [status, setStatus] = useState<Status>(Status.IDLE);

  const [powerModeUser, setPowerModeUser] = useState<PowerModeUser | null>(
    null
  );

  const [takeaways, setTakeaways] = useState<string[]>(['', '', '']);
  const [topic, setTopic] = useState<string>('');
  const [topics, setTopics] = useState<Topic[] | null>(null);

  const location = useLocation();
  const navigate = useNavigate();
  const deckTitle = location.state as string;

  const [user, loading] = useAuthState(auth);

  const gaEventTracker = useAnalyticsEventTracker();

  const regenerateSlides = async () => {
    if (topics) {
      try {
        gaEventTracker(
          'CUSTOM-DECK_RE-GENERATE-SLIDES',
          getEventLabel(deckTitle, topic)
        );
        setStatus(Status.GENERATING_SLIDES);
        setSlides(null);

        await getSlides(topics);

        setStatus(Status.IDLE);
        incrementCustomTotalGenerations();
      } catch (_) {
        setStatus(Status.ERROR);
      }
    }
  };

  const getStructure = async () => {
    let parsedTopics: Topic[] = [];

    const response = await fetch(getConfig().generateStructureUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        deckTitle,
        topic,
        takeaways,
        context: furtherContext,
      }),
    });

    if (response.body) {
      const reader = response.body
        .pipeThrough(new TextDecoderStream())
        .getReader();

      let buffer = '';

      for (
        let readResult = await reader.read();
        !readResult.done;
        readResult = await reader.read()
      ) {
        buffer += readResult.value;

        const parsed = parse(buffer) as { topics?: Partial<Topic>[] };

        if (parsed.topics && parsed.topics.length > 0) {
          parsedTopics = parsed.topics.map((t) => ({
            topic: t.topic || '',
            type: SlideType.AI_DECIDED,
          })) as Topic[];

          setTopics(parsedTopics);
        }
      }
    }

    return parsedTopics;
  };

  const getSlides = async (slideTopics: Topic[]) => {
    let parsedSlides: Slide[] = [];

    const response = await fetch(getConfig().generateSlidesPocUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        deckTitle,
        topic,
        takeaways,
        context: furtherContext,
        topics: slideTopics,
      }),
    });

    if (response.body) {
      const reader = response.body
        .pipeThrough(new TextDecoderStream())
        .getReader();

      let buffer = '';

      for (
        let readResult = await reader.read();
        !readResult.done;
        readResult = await reader.read()
      ) {
        buffer += readResult.value;

        const parsed = parse(buffer) as Slide[];

        if (parsed.length > 0) {
          parsedSlides = parsed.map(parsePartialSlide) as Slide[];

          setSlides(parsedSlides);
        }
      }
    }
  };

  useEffect(() => {
    if (user) {
      (async () => {
        setPowerModeUser(await fetchUser(user));
      })();
    } else if (!loading) {
      navigate('/');
    }
  }, [user, loading, navigate]);

  const generateSlides = async () => {
    try {
      gaEventTracker(
        'CUSTOM-DECK_GENERATE-SLIDES',
        getEventLabel(deckTitle, topic)
      );
      setStatus(Status.GENERATING_STRUCTURE);

      const generatedTopics = await getStructure();

      setStatus(Status.GENERATING_SLIDES);

      await getSlides(generatedTopics);

      incrementCustomDeckNewSlides();

      setStatus(Status.IDLE);
    } catch (error) {
      setStatus(Status.ERROR);
    }
  };

  const onDownloadSlides = () => {
    if (slides) {
      gaEventTracker('CUSTOM-DECK_DOWNLOAD-SLIDES');

      downloadSlides(slides);
    }
  };

  return (
    <GradientContainer>
      <MobileWarning />
      {powerModeUser ? (
        <>
          <SlideGenHeader user={powerModeUser} />

          <HStack
            pt={50}
            p={10}
            minW="250px"
            flex="1"
            width="100%"
            minH={0}
            overflowY="hidden"
            overflowX="auto"
          >
            <PrimaryPromptForm
              topic={topic}
              setTopic={setTopic}
              topics={topics}
              characterCount={characterCount}
              takeaways={takeaways}
              setTakeaways={setTakeaways}
              furtherContext={furtherContext}
              setFurtherContext={setFurtherContext}
              generateSlides={generateSlides}
              status={status}
              slides={slides}
            />

            <VStack
              height="100%"
              minH={0}
              minW={600}
              maxW="50%"
              flexGrow="1"
              pl="5px"
              pr="5px"
            >
              <Box marginBottom="5px" w="100%">
                {isLoadingStatus(status) && showLoadingMessage(status)}
                {status === Status.ERROR && (
                  <Alert status="error">
                    <AlertIcon />
                    <AlertTitle>Ooops!</AlertTitle>
                    <AlertDescription>
                      Something went wrong. Please try again later.
                    </AlertDescription>
                  </Alert>
                )}
                {slides && status === Status.IDLE && (
                  <Flex direction="row" justifyContent="space-between" w="100%">
                    <Box pl="20px" pr="20px">
                      <Text fontSize="26px" color="#fff">
                        Your slides are ready 🎉
                      </Text>
                    </Box>
                    <Box>
                      <Button onClick={onDownloadSlides} colorScheme="teal">
                        Download slides 🚀
                      </Button>
                    </Box>
                  </Flex>
                )}
              </Box>
              <VStack
                height="100%"
                minH={0}
                minW={600}
                overflow="auto"
                flexGrow="1"
                marginLeft="5px"
                marginRight="5px"
              >
                {slides && (
                  <VStack spacing={10}>
                    <SlidePreview slides={slides} />
                  </VStack>
                )}
              </VStack>
            </VStack>

            <PresentationStructureForm
              topics={topics}
              setTopics={setTopics}
              status={status}
              regenerateSlides={regenerateSlides}
              topic={topic}
            />
          </HStack>
        </>
      ) : (
        <Flex justifyContent="center" alignItems="center" h="full" w="full">
          <Spinner color="#fff" size="xl" />
        </Flex>
      )}
    </GradientContainer>
  );
};

export default GeneralPurposePoc;
