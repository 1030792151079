import { User } from 'firebase/auth';
import { query, collection, where, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase';
import { PowerModeUser } from '../../../types/PowerModeUser';

export const fetchUser = async (user: User): Promise<PowerModeUser> => {
  try {
    const q = query(collection(db, 'users'), where('uid', '==', user.uid));
    const doc = await getDocs(q);
    const data = doc.docs[0].data();

    return {
      usageCount: (data.customomDeckNewSlides as number) || 0,
      name: data.name as string,
      isPaidUser: data.isPaidUser as boolean,
    };
  } catch (err) {
    console.error(err);
    throw err;
  }
};
