import { AddIcon, CloseIcon } from '@chakra-ui/icons';
import {
  IconButton,
  Input,
  Select,
  VStack,
  HStack,
  Box,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { SlideType } from '../../../types/SlideType';
import { Topic } from '../../../types/Topic';

type ListInputProps = {
  maximumInputs: number;
  initialInputs: Topic[];
  onChange: (topics: Topic[]) => void;
  disabled?: boolean;
};

export const TopicsList = ({
  maximumInputs,
  initialInputs,
  onChange,
  disabled,
}: ListInputProps) => {
  const [inputs, setInputs] = useState<Topic[]>(
    initialInputs || [{ topic: '', type: SlideType.BulletPoints }]
  );

  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    setInputs(initialInputs);
  }, [initialInputs]);

  useEffect(() => {
    if (hasChanged) {
      onChange(inputs);
      setHasChanged(false);
    }
  }, [hasChanged, inputs, onChange]);

  return (
    <VStack flexGrow={1} width="100%">
      {inputs.map((input, idx) => (
        <VStack flexGrow={1} width="100%">
          {idx > 0 ? (
            <Box
              border="0.5px solid #757575"
              borderRadius="5px"
              width="80%"
              marginBottom="5px"
              marginTop="5px"
              h={0}
            />
          ) : undefined}
          <HStack flexGrow={1} width="100%">
            <div>#{idx + 1})</div>
            <Input
              width="100%"
              value={input.topic}
              size="sm"
              flexGrow={1}
              placeholder={`Slide #${idx + 1}`}
              onChange={(event) => {
                setHasChanged(true);
                setInputs((i) => {
                  const copy = [...i];
                  copy[idx].topic = event.target.value;
                  return copy;
                });
              }}
              disabled={disabled}
            />
          </HStack>
          <HStack width="100%">
            <div>Format</div>
            <Select
              onChange={(event) =>
                setInputs((i) => {
                  setHasChanged(true);
                  const copy = [...i];
                  copy[idx].type = event.target.value as SlideType;
                  return copy;
                })
              }
              autoFocus
              defaultValue="ai"
              disabled={disabled}
            >
              <option value="ai">Let AI decide</option>
              <option value="bullet-points">Bullet points</option>
              <option value="text">Text</option>
            </Select>
          </HStack>

          {inputs.length > 1 ? (
            <Box display="flex" justifyContent="flex-end" width="100%">
              <IconButton
                alignSelf="end"
                aria-label="Delete"
                colorScheme="red"
                icon={<CloseIcon />}
                tabIndex={-1}
                onClick={() => {
                  setHasChanged(true);
                  setInputs((i) => {
                    const copy = [...i];
                    copy.splice(idx, 1);
                    return copy;
                  });
                }}
                disabled={disabled}
              />
            </Box>
          ) : undefined}
        </VStack>
      ))}

      {maximumInputs <= inputs.length ? undefined : (
        <Box display="flex" justifyContent="flex-end" width="100%">
          <IconButton
            alignSelf="end"
            aria-label="Add"
            colorScheme="teal"
            icon={<AddIcon />}
            tabIndex={-1}
            onClick={() => {
              setHasChanged(true);
              setInputs((i) => [
                ...i,
                { topic: '', type: SlideType.BulletPoints },
              ]);
            }}
            disabled={disabled}
          />
        </Box>
      )}
    </VStack>
  );
};
