import {
  Container,
  Box,
  Text,
  Heading,
  Image,
  Flex,
  Link,
  Button,
} from '@chakra-ui/react';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  RedditShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  RedditIcon,
  EmailIcon,
} from 'react-share';

import useAnalyticsEventTracker from '../hooks/useAnalyticsEventTracker';

const Contact = () => {
  const shareUrl = 'https://powermodeai.com/';
  const title = 'Power Mode - AI powered pitch slides in seconds';
  const gaEventTracker = useAnalyticsEventTracker('Homepage');

  return (
    <Container maxW="full" mt={0} centerContent id="contact">
      <Box
        bg="white"
        color="white"
        borderRadius="lg"
        m={{ sm: 4, md: 16, lg: 16 }}
      >
        <Flex alignItems="center" flexDirection={{ base: 'column', xl: 'row' }}>
          <Flex
            direction="column"
            gridArea="text"
            textAlign="center"
            gap={5}
            p={{ base: 5, sm: 5, md: 5, lg: 16 }}
            mx={{ md: 20 }}
          >
            <Heading
              fontWeight={400}
              fontSize={{ base: 'xl', sm: '2xl', md: '4xl' }}
              lineHeight="110%"
              color="black"
              px={{ md: 15 }}
            >
              We'd love to chat!
            </Heading>
            <Text
              color="gray.500"
              display={{ base: 'none', md: 'block' }}
              px={{ md: 20 }}
            >
              We wanted to play around with what GPT-3 can do and create
              something other founding teams may find helpful. Please send us
              feedback on our project and share the love!
            </Text>

            <Link
              href="https://calendly.com/danni-shah/powermode-20-min-feedback-session"
              isExternal
            >
              <Button
                onClick={() =>
                  gaEventTracker('Clicked share feedback (homepage)')
                }
                variant="solid"
                size="md"
                colorScheme="blackAlpha"
                bg="black"
                m="2px"
                mb={4}
              >
                Book a call!
              </Button>
            </Link>
            <Box px={{ md: 15 }}>
              <Text color="gray.500" textAlign="center" mt={2} as="b">
                Or contact us at info@powermodeai.com
              </Text>
            </Box>
            <Box>
              <Text color="gray.500" textAlign="center" mt={2} mb={2}>
                Like what you see? Share it:
              </Text>
              <FacebookShareButton url={shareUrl} quote={title}>
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <TwitterShareButton url={shareUrl} title={title}>
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              <LinkedinShareButton url={shareUrl}>
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
              <RedditShareButton
                url={shareUrl}
                title={title}
                windowWidth={660}
                windowHeight={460}
              >
                <RedditIcon size={32} round />
              </RedditShareButton>

              <EmailShareButton
                url={shareUrl}
                subject={title}
                body="I've been using PowerMode to ideate and generate slides driven by AI"
              >
                <EmailIcon size={32} round />
              </EmailShareButton>
            </Box>
          </Flex>
          <Image
            gridArea="image"
            src="/astronaut.png"
            w="fit-content"
            py={{ base: 5, sm: 5, md: 5, lg: 16 }}
          />
        </Flex>
      </Box>
    </Container>
  );
};

export default Contact;
