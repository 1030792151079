import {
  useToast,
  Flex,
  Input,
  Button,
  Box,
  Link,
  Icon,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { FaGoogle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import {
  auth,
  registerWithEmailAndPassword,
  signInWithGoogle,
} from '../../firebase';
import useAnalyticsEventTracker from '../../hooks/useAnalyticsEventTracker';
import { getErrorMessage } from '../../utils';

interface RegisterProps {
  showLogin: boolean;
  setShowLogin: (value: boolean) => void;
  onClose: () => void;
}

const Register = ({ showLogin, setShowLogin, onClose }: RegisterProps) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [user, loading, error] = useAuthState(auth);
  const gaEventTracker = useAnalyticsEventTracker('Register');
  const navigate = useNavigate();
  const toast = useToast();

  const handleRegister = async () => {
    if (!name)
      toast({
        title: 'Small issue 😐',
        description: 'Please enter your name',
        status: 'warning',
        duration: 1000,
        isClosable: true,
      });
    try {
      await registerWithEmailAndPassword(name, email, password);
      if (name && !error)
        toast({
          title: 'Account created.',
          description: "We've created your account for you.",
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      gaEventTracker('User signup successful');
    } catch (err) {
      toast({
        title: 'Small issue 😐',
        description: `${getErrorMessage(err)}`,
        status: 'warning',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (loading) return;
    if (user) {
      toast({
        title: 'Login successful',
        description: "You're now logged in",
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      onClose();
    }
    if (error) console.log('Register error', error);
    // eslint-disable-next-line
  }, [user, loading, error, navigate]);

  return (
    <Flex w="100%" wrap="wrap" gap={2}>
      <Input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Full Name"
      />
      <Input
        type="text"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="E-mail Address"
      />
      <Input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
      />
      <Button
        variant="solid"
        size="md"
        colorScheme="blackAlpha"
        bg="black"
        m="2px"
        onClick={handleRegister}
      >
        Register
      </Button>
      <Button
        variant="outline"
        size="md"
        colorScheme="blackAlpha"
        m="2px"
        color="black"
        onClick={signInWithGoogle}
      >
        <Icon as={FaGoogle} mr={2} /> Login with Google
      </Button>

      <Box>
        Already have an account?{' '}
        <Link color="teal.500" onClick={() => setShowLogin(!showLogin)}>
          Login now
        </Link>
      </Box>
    </Flex>
  );
};

export default Register;
