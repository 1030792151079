import PPTXGen from 'pptxgenjs';
import { Slide } from '../../../types/Slide';
import { SlideType } from '../../../types/SlideType';

const Spacing = {
  left: 0.5,
};

const Header = {
  fontSize: 24,
  top: 0.5,
};

const Content = {
  fontSize: 18,
  top: 2,
};

export const downloadSlides = (slides: Slide[]) => {
  const pptx = new PPTXGen();

  for (const slide of slides) {
    const pptxSlide = pptx.addSlide();
    pptxSlide.addText(slide.title, {
      x: Spacing.left,
      y: Header.top,
      align: 'left',
      fontSize: Header.fontSize,
    });

    if (slide.type === SlideType.BulletPoints) {
      for (let i = 0; i < slide.content.length; i++) {
        pptxSlide.addText(slide.content[i], {
          x: Spacing.left,
          y: i + Content.top,
          align: 'left',
          fontSize: Content.fontSize,
          bullet: true,
        });
      }
    } else if (slide.type === SlideType.Text) {
      pptxSlide.addText(slide.content, {
        x: Spacing.left,
        y: Content.top,
        align: 'left',
        fontSize: Content.fontSize,
      });
    }
  }

  pptx.writeFile({
    fileName: `slides.pptx`,
  });
};
