import { VStack } from '@chakra-ui/react';

type Props = {
  children: React.ReactElement;
};

export const Form = ({ children }: Props) => (
  <VStack
    w="200px"
    bg="blackAlpha.500"
    color="white"
    flex={1}
    minH={0}
    height="100%"
    overflowY="auto"
    border="2px solid white"
    margin={0}
  >
    {children}
  </VStack>
);
