import { ReactNode } from 'react';

import { Box, Flex, Link } from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';

import Logo from './Logo';

interface NavBarProps {
  children?: ReactNode;
}

const NavBar = ({ children }: NavBarProps) => (
  <Box px={4}>
    <Flex h={16} alignItems="center" justifyContent="space-between">
      <Box display="flex" alignItems="center">
        <Link as={ReactRouterLink} to="/">
          <Logo h={20} />
        </Link>
      </Box>
      {children}
    </Flex>
  </Box>
);

export default NavBar;
