import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import ReactGA from 'react-ga4';

import '@fontsource/inter/400.css';
import '@fontsource/doppio-one/400.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import theme from './theme';
import NewLandingPage from './components/NewLandingPage';
import { Deck } from './components/deckCreation';
import GeneralPurposePoc from './components/GeneralPurposePoc/GeneralPurposePoc';
import GenerateIdea from './components/GenerateIdea';

const MEASUREMENT_ID = 'G-XN9V4X6X9F';
ReactGA.initialize(MEASUREMENT_ID);

const queryClient = new QueryClient();

export const App = () => (
  <QueryClientProvider client={queryClient}>
    <ChakraProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<NewLandingPage />} />{' '}
          <Route path="/start" element={<Deck type="start-up-pitch" />} />
          <Route path="/general-purpose" element={<GeneralPurposePoc />} />
          <Route path="/generate-idea" element={<GenerateIdea />} />
        </Routes>
      </Router>
    </ChakraProvider>
  </QueryClientProvider>
);
