import { Link } from 'react-router-dom';
import {
  Box,
  Image,
  Button,
  Flex,
  Spacer,
  Text,
  useToast,
} from '@chakra-ui/react';

import { logout } from '../../../firebase';
import { PowerModeUser } from '../../../types/PowerModeUser';

interface Props {
  user: PowerModeUser;
}

const SlideGenHeader: React.FC<Props> = ({ user }) => {
  const toast = useToast();

  const handleLogout = () => {
    logout();
    toast({
      title: 'Logout Success',
      description: "You've signed out successfully",
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  };

  return (
    <Flex
      alignItems="space-between"
      gap="2"
      borderBottom="5px solid white"
      width="100%"
    >
      <Box h="108px" display="flex" alignItems="center" w="100%">
        <Link to="/">
          <Image src="/LogoWhite.svg" h={16} />
        </Link>
      </Box>
      <Spacer />
      {user && (
        <Flex gap={2} p={5}>
          <Text fontSize="sm" color="#fff">
            <b>Signed in as: </b>
            {user.name} {user.isPaidUser && <>(Paid user)</>}
            {user.usageCount && <b> Decks built: {user.usageCount}</b>}
          </Text>
          <Button
            variant="outline"
            size="md"
            colorScheme="blackAlpha"
            m="2px"
            color="#fff"
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

export default SlideGenHeader;
