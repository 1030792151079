import { Box, Flex } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { ResponsePayload } from '../../../client/backendClient';

const SlideContainer = ({
  children,
  justify,
  align,
  slideData,
  heading,
}: {
  children: any;
  justify?: string;
  align?: string;
  slideData: ResponsePayload['slides'][number];
  heading?: ReactElement;
}) => (
  <Box w="95%" overflowX="auto">
    <Box
      bgColor={slideData.slideFormatting?.backgroundColour || '#FFFFFF'}
      w="800px"
      h="450px"
      position="relative"
    >
      {heading}
      <Flex
        w="100%"
        h="100%"
        justify={justify || 'start'}
        align={align || 'center'}
        direction="column"
        flexShrink={0}
        padding="60px"
      >
        {children}
      </Flex>
    </Box>
  </Box>
);

export default SlideContainer;
