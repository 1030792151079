import { useEffect, useState } from 'react';
import { Box, Text, useMediaQuery } from '@chakra-ui/react';

const MobileWarning = () => {
  const [isMobile, setIsMobile] = useState(false);

  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  useEffect(() => {
    setIsMobile(!isLargerThan768);
  }, [isLargerThan768]);

  return isMobile ? (
    <Box position="fixed" top={0} left={0} right={0} bg="red.500" zIndex={9999}>
      <Text color="white" fontSize="md" textAlign="center" p={2}>
        Warning: This app is not suitable for mobile devices.
      </Text>
    </Box>
  ) : null;
};

export default MobileWarning;
