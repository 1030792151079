import {
  Box,
  Flex,
  useDisclosure,
  Text,
  Image,
  Container,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import ScrollIntoViewIfNeeded from 'react-scroll-into-view-if-needed';

import { useState } from 'react';
import NewHero from './NewHero';
import ContactCTA from './ContactCTA';
import AuthModal from './auth/AuthModal';
import Testimonials from './Testimonials';
import Benefits from './Benifits';
import NavBar from './NavBar';

import Menu from './NavBar/Menu';

import { AuthParams } from '../types/AuthParams';
import useContactScrollToggle from '../hooks/useContactScrollToggle';

const NewLandingPage = () => {
  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [authParams, setAuthParams] = useState<AuthParams | null>(null);
  const { active, handleScrollToggle } = useContactScrollToggle();

  const onOpenWrapper = (params: AuthParams) => {
    onOpen();
    setAuthParams(params);
  };

  const onCloseWrapper = () => {
    onClose();

    if (authParams) {
      navigate(authParams.next, { state: authParams.state });
    }
  };

  return (
    <Box
      bg="url(/background2.jpg)"
      backgroundSize="cover"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
    >
      <NavBar>
        <Menu onOpen={onOpenWrapper} handleScrollToggle={handleScrollToggle} />
      </NavBar>

      <NewHero onOpen={onOpenWrapper} />
      <AuthModal isOpen={isOpen} onClose={onCloseWrapper} />
      <Container
        centerContent
        maxWidth="full"
        mt={10}
        bg="blackAlpha.200"
        pb={8}
      >
        <Flex
          direction={{ base: 'column', md: 'row' }}
          alignItems="center"
          justifyContent="space-between"
          width="full"
          mx="auto"
          mt={8}
          p={8}
        >
          <Box flex={2}>
            <Text fontSize="2xl" mb={4}>
              Say goodbye to the hassle and frustration of creating polished
              presentations from scratch.
            </Text>
            <Text fontSize="2xl" mb={4}>
              With our AI-powered slide generator, all you need to do is answer
              a few prompts and voila!
            </Text>
            <Text fontSize="2xl" mb={4}>
              You'll have a fully developed deck that will help you sell, teach,
              or present with ease and professionalism.
            </Text>
          </Box>
          <Box flex={1} ml={{ base: 0, md: 8 }}>
            <Image
              alt="Astronaut giving a presentation"
              gridArea="image"
              src="/astroStage.png"
              w="fit-content"
              py={3}
            />
          </Box>
        </Flex>
      </Container>
      <Testimonials />
      <Benefits />
      <ScrollIntoViewIfNeeded active={active}>
        <ContactCTA />
      </ScrollIntoViewIfNeeded>
    </Box>
  );
};

export default NewLandingPage;
