import { useState } from 'react';

const useContactScrollToggle = () => {
  const [active, setActive] = useState(false);

  const handleScrollToggle = () => {
    setActive(!active);

    if (!active) {
      setTimeout(() => {
        setActive(false);
      }, 2000);
    }
  };

  return { active, handleScrollToggle };
};

export default useContactScrollToggle;
