import pptxgen from 'pptxgenjs';
import { getHeadersAndText, getListFormattedLines } from '.';
import { ResponsePayload, Text, Title } from '../../client/backendClient';

export type Slide = 'company' | 'industry' | 'competition';

export type SlideDataType = {
  [key in Slide]: {
    completed: boolean;
    data: ResponsePayload['slides'];
  };
};

export function generateTitleSlide(
  deck: pptxgen,
  slideData: ResponsePayload['slides'],
  deckType: string
) {
  if (slideData.length !== 1) {
    throw new Error(
      'We found more than 1 slide for the title slide, that is unexpected...'
    );
  }

  const slideDatum = slideData[0];

  const slide = deck.addSlide();

  slide.addText(
    [
      generateBasicText(
        {
          ...slideDatum.text,
          textFormatting: {
            colour: '#FFFFFF',
          },
        },
        { align: 'center', bullet: false, bold: true }
      ),
      // we only show the pitch deck text for the start up pitch
      ...(deckType === 'start-up-pitch'
        ? [
            generateBasicText(
              {
                value: 'Pitch Deck #1',
                textFormatting: {
                  colour: '#FFFFFF',
                },
              },
              { align: 'center', bullet: false, bold: true }
            ),
          ]
        : []),
    ],
    {
      y: '75%',
      x: '2%',
      h: '10%',
      w: '25%',
    }
  );

  slide.addImage({
    path: 'https://storage.googleapis.com/assets-vjklgvkgfcv/title_slide_image.png',
    y: '10%',
    x: '55%',
    sizing: {
      w: '40%',
      h: '80%',
      type: 'cover',
    },
  });

  slide.addText(
    [
      generateBasicText({
        value: 'Made with GPT-3.5 and ',
        textFormatting: { colour: '#FFFFFF', size: 15 },
      }),
    ],
    {
      y: '88%',
      x: '60%',
      w: '25%',
      h: '10%',
    }
  );

  slide.addImage({
    path: 'https://storage.googleapis.com/assets-vjklgvkgfcv/LogoWhite.svg',
    y: '88%',
    x: '77.5%',
    w: '22%',
    h: '10%',
    sizing: {
      w: '20%',
      h: '10%',
      type: 'contain',
    },
  });

  slide.background = {
    color: '#000000',
  };

  return deck;
}

const transformTitleToText = (title: Title): Text => ({
  value: title.value,
  textFormatting: {
    colour: title.colour,
    size: title.size,
  },
});

export const generateSlides = (
  deck: pptxgen,
  slides: ResponsePayload['slides']
) => {
  slides.forEach((slideDataItem) => {
    const slide = deck.addSlide();

    if (slideDataItem.slideLabel) {
      slide.addText(
        [
          generateBasicText(transformTitleToText(slideDataItem.slideLabel), {
            align: 'left',
            bullet: false,
            bold: false,
          }),
        ],
        {
          y: '5%',
          x: 0.1,
          w: '50%',
          align: 'left',
        }
      );
    }

    slide.addText(
      [
        generateBasicText(
          slideDataItem.title
            ? transformTitleToText(slideDataItem.title)
            : { value: '' },
          {
            align: slideDataItem.slideFormatting?.titleLocation || 'left',
            bullet: false,
            bold: true,
          }
        ),
      ],
      { y: '15%', x: '5%', w: '90%', h: '10%' }
    );

    generateSlideContent(slide, slideDataItem.text);

    slide.background = {
      color: slideDataItem.slideFormatting?.backgroundColour || '#FFFFFF',
    };
  });

  return deck;
};

const generateSlideContent = (
  slide: pptxgen.Slide,
  text: Text
): pptxgen.Slide => {
  let newSlide = slide;

  if (text.contentFormatting === 'headers') {
    const headerTextPairs = getHeadersAndText(text);
    const sectionCount = headerTextPairs.length;
    const sectionPercent = 100 / sectionCount;

    newSlide = headerTextPairs.reduce(
      (slide1, { header }, idx) =>
        slide1.addText(header, {
          x: `${sectionPercent * idx}%`,
          w: `${sectionPercent}%`,
          align: 'center',
          y: '25%',
          h: '10%',
        }),
      newSlide
    );

    newSlide = headerTextPairs.reduce(
      (slide1, _, idx) =>
        slide1.addShape('line', {
          line: {
            color: parseColour(text.textFormatting?.colour || '#000000'),
            width: 1,
          },
          x: `${sectionPercent * idx + 10}%`,
          w: `${sectionPercent - 20}%`,
          y: '35%',
          h: 0,
        }),
      newSlide
    );

    newSlide = headerTextPairs.reduce(
      (slide1, { text: textValue }, idx) =>
        slide1.addText([generateBasicText({ ...text, value: textValue })], {
          x: `${sectionPercent * idx + 5}%`,
          w: `${sectionPercent - 10}%`,
          align: 'center',
          y: '50%',
        }),
      newSlide
    );

    return newSlide;
  }

  if (text.contentFormatting === 'bullet-list') {
    const lines = getListFormattedLines(text.value);
    newSlide = newSlide.addText(
      lines.map((line) =>
        generateBasicText(
          { ...text, value: line },
          { align: 'left', bullet: true, bold: false }
        )
      ),
      {
        valign: 'top',
        y: '25%',
        x: '5%',
        h: '50%',
        w: '90%',
      }
    );

    return newSlide;
  }

  newSlide = newSlide.addText([generateBasicText(text)], {
    valign: 'top',
    y: '25%',
    x: '5%',
    h: '50%',
    w: '90%',
  });

  return newSlide;
};

function generateBasicText(
  text: Text,
  {
    align,
    bullet,
    bold,
  }: {
    align: pptxgen.HAlign;
    bullet: boolean;
    bold: boolean;
  } = {
    align: 'left',
    bullet: false,
    bold: false,
  }
): pptxgen.TextProps {
  return {
    text: text.value,
    options: {
      bold,
      fontSize: text.textFormatting?.size || 20,
      color: parseColour(text.textFormatting?.colour || '#000000'),
      align,
      breakLine: true,
      bullet,
    },
  };
}

// pptxgen doesn't accept colours with the starting #
const parseColour = (colour: string) => colour.replace('#', '');
