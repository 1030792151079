import { CloseIcon, AddIcon } from '@chakra-ui/icons';
import { HStack, IconButton, Input, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

type ListInputProps = {
  maximumInputs: number;
  initialInputs: string[];
  onChange?: (inputs: string[]) => void;
};

export const ListInput = ({
  maximumInputs,
  initialInputs,
  onChange,
}: ListInputProps) => {
  const [inputs, setInputs] = useState<string[]>(initialInputs || ['']);

  useEffect(() => {
    if (onChange) {
      onChange(inputs);
    }
  }, [inputs, onChange]);

  return (
    <VStack width="100%">
      {inputs.map((input, idx) => (
        <HStack width="100%" key={idx}>
          <Input
            value={input}
            size="sm"
            flexGrow={1}
            placeholder={`Most important thing #${idx + 1}`}
            onChange={(event) => {
              setInputs((i) => {
                const copy = [...i];
                copy[idx] = event.target.value;
                return copy;
              });
            }}
          />
          {inputs.length > 1 ? (
            <IconButton
              aria-label="Delete"
              colorScheme="red"
              margin="10px"
              icon={<CloseIcon />}
              tabIndex={-1}
              onClick={() =>
                setInputs((i) => {
                  const copy = [...i];
                  copy.splice(idx, 1);
                  return copy;
                })
              }
            />
          ) : undefined}
        </HStack>
      ))}

      {maximumInputs <= inputs.length ? undefined : (
        <IconButton
          alignSelf="end"
          aria-label="Add"
          colorScheme="teal"
          margin="10px"
          icon={<AddIcon />}
          tabIndex={-1}
          onClick={() => setInputs((i) => [...i, ''])}
        />
      )}
    </VStack>
  );
};
