import { Box, Avatar, Text, Stack, Flex } from '@chakra-ui/react';

const Testimonials = () => {
  const testimonials = [
    {
      id: 1,
      name: 'William Leborgne - Founder and CEO',
      avatarUrl:
        'https://lh3.googleusercontent.com/a-/ACB-R5T_8ouxvNbBfERRoYcofcgSwl2oRQMXzOkI4BIu0w=s64-p-k-rw-no',
      message:
        'Using Power Mode AI to build my sales deck was a game-changer. The tool was incredibly user-friendly, and I was able to iterate through four different decks in the same amount of time it would have taken me to build just one on my own. And it paid off: that deck ultimately helped me win over a new client.',
    },
  ];

  return (
    <Box maxWidth="1200px" mx="auto" mt={8} p={8}>
      <Stack spacing={8}>
        {testimonials.map((testimonial) => (
          <Box key={testimonial.id} position="relative">
            <Box
              borderRadius="lg"
              backgroundColor="gray.100"
              p={4}
              position="relative"
              _before={{
                content: `""`,
                display: 'block',
                position: 'absolute',
                bottom: '-20px',
                left: '50%',
                transform: 'translateX(-50%) rotate(45deg)',
                width: '20px',
                height: '20px',
                backgroundColor: 'gray.100',
                boxShadow: 'md',
              }}
            >
              <Text fontSize="lg">{testimonial.message}</Text>
            </Box>
            <Flex justifyContent="flex-start" pt={4} alignItems="center">
              <Avatar src={testimonial.avatarUrl} size="sm" />
              <Text pl={2} fontSize="sm" fontWeight="bold">
                {testimonial.name}
              </Text>
            </Flex>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default Testimonials;
