/* eslint-disable */
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from 'firebase/auth';
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
  doc,
  updateDoc,
} from 'firebase/firestore';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
  authDomain: 'powermode-75e64.firebaseapp.com',
  projectId: 'powermode-75e64',
  storageBucket: 'powermode-75e64.appspot.com',
  messagingSenderId: '452716786252',
  appId: '1:452716786252:web:4598d7847c807168a37ae9',
  measurementId: 'G-4CG9B6B1CW',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const analytics = getAnalytics(app);

const googleProvider = new GoogleAuthProvider();

const incrementUsageCount = () => incrementUsageParam(['usage']);

const incrementCustomDeckNewSlides = () =>
  incrementUsageParam(['customomDeckNewSlides', 'customDeckTotalGenerations']);

const incrementCustomTotalGenerations = () =>
  incrementUsageParam(['customDeckTotalGenerations']);

const incrementUsageParam = async (params: string[]) => {
  const user = auth.currentUser;
  if (user) {
    const uid = user.uid;

    try {
      const q = query(collection(db, 'users'), where('uid', '==', uid));
      const querySnapshot = await getDocs(q);
      const docID = querySnapshot.docs[0].id;
      const data = querySnapshot.docs[0].data();

      const userRef = doc(db, 'users', `${docID}`);

      const paramsToUpdate: { [key: string]: number } = {};

      for (const param of params) {
        paramsToUpdate[param] = data[param] ? data[param] + 1 : 1;
      }


      await updateDoc(userRef, paramsToUpdate);
    } catch (err) {
      console.log(err);
    }
  } else {
    console.log('user is not logged in');
  }
};

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const { user } = res;
    const q = query(collection(db, 'users'), where('uid', '==', user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, 'users'), {
        uid: user.uid,
        name: user.displayName,
        authProvider: 'google',
        email: user.email,
      });
    }
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const logInWithEmailAndPassword = async (email: string, password: string) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    throw err;
  }
};

const registerWithEmailAndPassword = async (
  name: string,
  email: string,
  password: string
) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const { user } = res;
    await addDoc(collection(db, 'users'), {
      uid: user.uid,
      name,
      authProvider: 'local',
      email,
    });
  } catch (err) {
    throw err;
  }
};

const sendPasswordReset = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert('Password reset link sent!');
  } catch (err) {
    console.error(err);
    alert((err as Error).message);
  }
};

const logout = () => {
  signOut(auth);
};

export {
  auth,
  db,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  incrementUsageCount,
  incrementCustomDeckNewSlides,
  incrementCustomTotalGenerations,
  analytics,
};
