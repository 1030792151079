import { Box, Link, Text } from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';

interface DesktopMenuProps {
  onGeneratePitch: () => void;
  handleScrollToggle: () => void;
}

const DesktopMenu = ({
  onGeneratePitch,
  handleScrollToggle,
}: DesktopMenuProps) => (
  <Box
    display={{ base: 'none', md: 'flex' }}
    width={{ base: 'full', md: 'auto' }}
    alignItems="center"
    flexGrow={1}
    justifyContent="flex-end"
  >
    <Text pr={4} onClick={onGeneratePitch}>
      <Link>Generate Pitch</Link>
    </Text>
    <Text pr={4}>
      <Link as={ReactRouterLink} to="/generate-idea">
        Generate Idea
      </Link>
    </Text>
    <Text cursor="pointer" pr={4}>
      <Link onClick={handleScrollToggle}>Contact us!</Link>
    </Text>
  </Box>
);

export default DesktopMenu;
