import { QuestionOutlineIcon } from '@chakra-ui/icons';
import { Button, Text, Textarea, Tooltip, VStack } from '@chakra-ui/react';
import { Slide } from '../../../types/Slide';
import { Topic } from '../../../types/Topic';
import { Form } from '../Form/Form';
import {
  getLoadingMessage,
  isLoadingStatus,
  MAX_CHARACTER_COUNT,
  Status,
} from '../GeneralPurposePoc';
import { ListInput } from '../ListInput/ListInput';

type Props = {
  topic: string;
  setTopic: (topic: string) => void;
  topics: Topic[] | null;
  characterCount: number;
  takeaways: string[];
  setTakeaways: (takeaways: string[]) => void;
  furtherContext: string;
  setFurtherContext: (furtherContext: string) => void;
  generateSlides: () => void;
  status: Status;
  slides: Slide[] | null;
};

export const PrimaryPromptForm = ({
  topic,
  setTopic,
  topics,
  characterCount,
  takeaways,
  setTakeaways,
  furtherContext,
  setFurtherContext,
  generateSlides,
  status,
  slides,
}: Props) => {
  const isOverLimit = characterCount > MAX_CHARACTER_COUNT;

  return (
    <Form>
      <VStack flex="1" minH={0}>
        <VStack
          as="form"
          onSubmit={(e) => e.preventDefault()}
          p={5}
          color="white"
          borderRadius={30}
          h="fit-content"
          w="100%"
        >
          <Text fontSize="lg" mb="8px">
            What is the topic of your deck?
            <Tooltip
              label={`A good topic should consider the following criteria: 
        Relevance; Ensure your topic is relevant to your audience's interests, needs, or concerns.  
        Clarity; Well defined with a clear focus, 
        Novelty; A unique or innovative topic, 
        Purpose; Align your topic with the purpose of your presentation,
        Personal interest and expertise; Select a topic that you are passionate about and have knowledge in`}
              placement="right-end"
              hasArrow
            >
              <QuestionOutlineIcon ml={2} />
            </Tooltip>
          </Text>

          <Textarea
            value={topic}
            onChange={(event) => setTopic(event.target.value)}
            placeholder="e.g. A product called PowerMode, Project update, Selling something"
            size="md"
            autoFocus
          />
        </VStack>
        {!topics && (
          <VStack
            as="form"
            onSubmit={(e) => e.preventDefault()}
            p={5}
            color="white"
            borderRadius={30}
            h="fit-content"
            maxW="100%"
          >
            <Text fontSize="lg" mb="8px">
              What are the most important things your audience should learn from
              your presentation?
            </Text>
            <Text fontSize="lg" mb="8px">
              We recommend listing the 3 most important, but you can add more!
            </Text>

            <ListInput
              initialInputs={takeaways}
              maximumInputs={10}
              onChange={(values) => setTakeaways(values)}
            />
          </VStack>
        )}
        <VStack
          as="form"
          onSubmit={(e) => e.preventDefault()}
          p={5}
          color="white"
          borderRadius={30}
          h="fit-content"
          w="100%"
        >
          <Text fontSize="lg" mb="8px">
            (optional) provide any additional context about the topic here.
            <Tooltip
              label="The more context you provide, the better the output will be.
        Feel free to copy notes or even entire documents. Further hints: 
        Ensure contextual relevance, include tone and style, specify that the 
        content should be factually accurate, clearly communicate specific data 
        you'd like included, remember to review and edit ;-)"
              placement="right-end"
              hasArrow
            >
              <QuestionOutlineIcon ml={2} />
            </Tooltip>
          </Text>

          <Textarea
            value={furtherContext}
            onChange={(event) => setFurtherContext(event.target.value)}
            isInvalid={isOverLimit}
            placeholder="E.g paste your strategy document notes or product spec"
            size="md"
          />
          {isOverLimit && (
            <Text color="gray.500">
              Caution! For best results, please reduce your input to{' '}
              {MAX_CHARACTER_COUNT} or less.
            </Text>
          )}
          <Text color={isOverLimit ? 'red.500' : 'gray.500'}>
            {characterCount} / {MAX_CHARACTER_COUNT} characters
          </Text>
        </VStack>
        {!topics && !slides && (
          <VStack
            as="form"
            onSubmit={(e) => e.preventDefault()}
            p={5}
            bg="blackAlpha.500"
            color="white"
            borderRadius={30}
            h="fit-content"
            maxW="100%"
          >
            <Button
              type="submit"
              colorScheme="teal"
              onClick={generateSlides}
              isLoading={isLoadingStatus(status)}
              loadingText={getLoadingMessage(status)}
              isDisabled={
                topic.length === 0 ||
                takeaways.length === 0 ||
                takeaways.every((t) => t.length === 0)
              }
            >
              Generate slides
            </Button>
          </VStack>
        )}
      </VStack>
    </Form>
  );
};
