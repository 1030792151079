const envs = ['dev', 'stage', 'prod'] as const;
type Env = typeof envs[number];

type Config = {
  mailchimpEnabled: boolean;
  generateSlideUrl: string;
  payUrl: string;
  generateStructureUrl: string;
  generateSlidesPocUrl: string;
};

const configs: Record<Env, Config> = {
  dev: {
    mailchimpEnabled: false,
    generateSlideUrl: 'http://localhost:8080/generateSlide',
    payUrl: 'http://localhost:8080/generateSlide/pay-staging',
    generateStructureUrl: 'http://localhost:8080/structure',
    generateSlidesPocUrl: 'http://localhost:8080/slides',
  },
  stage: {
    mailchimpEnabled: false,
    generateSlideUrl:
      process.env.REACT_APP_GENERATE_SLIDE_URL ||
      'https://europe-west2-augmented-works-369514.cloudfunctions.net/generateSlide-staging',
    payUrl:
      process.env.REACT_APP_PAY_URL ||
      'https://europe-west2-augmented-works-369514.cloudfunctions.net/pay-staging',
    generateStructureUrl:
      'https://powermode-stream-api-staging-6zu3tdgura-nw.a.run.app/structure',
    generateSlidesPocUrl:
      'https://powermode-stream-api-staging-6zu3tdgura-nw.a.run.app/slides',
  },
  prod: {
    mailchimpEnabled: true,
    generateSlideUrl:
      'https://europe-west2-augmented-works-369514.cloudfunctions.net/generateSlide-prod',
    payUrl:
      'https://europe-west2-augmented-works-369514.cloudfunctions.net/pay-prod',
    generateStructureUrl:
      'https://powermode-stream-api-prod-6zu3tdgura-nw.a.run.app/structure',
    generateSlidesPocUrl:
      'https://powermode-stream-api-prod-6zu3tdgura-nw.a.run.app/slides',
  },
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
const isValidEnv = (env: string): env is Env => envs.includes(env as any);

export const getConfig = () => {
  const env = process.env.REACT_APP_ENV || 'prod';

  if (!isValidEnv(env)) {
    throw new Error(`Invalid env set: ${env}`);
  }

  return configs[env];
};

export const config = getConfig();
