import { Box, Button, Text, Link } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';

import { pay } from '../../../client/backendClient';
import { auth } from '../../../firebase';
import useAnalyticsEventTracker from '../../../hooks/useAnalyticsEventTracker';

const BuyLifetimeAccess: React.FC = () => {
  const gaEventTracker = useAnalyticsEventTracker('Paywall');

  const [user] = useAuthState(auth);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    gaEventTracker('User shown paywall message');
  }, [gaEventTracker]);

  const onPay = async () => {
    if (user) {
      setIsLoading(true);
      gaEventTracker('Clicked Buy Lifetime Access');

      const { url } = await pay({
        userId: user.uid,
        email: user.email as string,
      });

      window.location.href = url;
    }
  };

  return (
    <Box textAlign="center" marginTop={10}>
      <Text mb={5} color="#FFFFFF">
        Thanks for being a fan of PowerMode AI! You’ve reached your limit of
        free AI-powered deck creations but don't worry - If you’d like to keep
        using our AI builder you have two options:
      </Text>
      <Text color="#FFFFFF" mb={2}>
        Share your feedback with our team - we’re always looking for ways to
        improve our product{' '}
      </Text>
      <Link
        href="https://calendly.com/danni-shah/powermode-20-min-feedback-session"
        isExternal
      >
        <Button
          onClick={() => gaEventTracker('Clicked share feedback (paywall)')}
          variant="solid"
          size="md"
          colorScheme="blackAlpha"
          bg="black"
          m="2px"
          mb={4}
        >
          Share feedback :-)
        </Button>
      </Link>
      <Text color="#FFFFFF" mb={2}>
        Pay a one time fee of $9.99 and you’ll have unlimited access to
        PowerMode AI{' '}
      </Text>

      <Button onClick={onPay} colorScheme="teal" isLoading={isLoading} mb={2}>
        Buy lifetime access for $9.99 🚀
      </Button>
      <Text color="#FFFFFF">info@powermodeai.com</Text>
    </Box>
  );
};

export default BuyLifetimeAccess;
