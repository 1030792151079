import { Box, Text, Heading } from '@chakra-ui/react';

interface CompanyIdeaCardProps {
  output: string;
}

const CompanyIdeaCard = ({ output }: CompanyIdeaCardProps) => {
  const formattedOutput = output.replace('2.0', 'v2');
  const regexCreateList = /(\d+\.\d*)\s?(.*?)(?=\d+\.|$)/gs;
  const [productName, explanation, missionStatement] = formattedOutput.match(
    regexCreateList
  ) as string[];

  const regexMatchNumbers = /\d+\./g;

  const cleanText = (sentence: string) =>
    sentence.replace(regexMatchNumbers, '');

  const product = cleanText(productName);
  const idea = cleanText(explanation);
  const mission = cleanText(missionStatement);

  return (
    <Box
      bg="white"
      color="black"
      borderRadius="lg"
      m={{ sm: 4, md: 16, lg: 10 }}
      p={{ base: 4, sm: 5, md: 5, lg: 16 }}
      textAlign="center"
    >
      <Heading fontWeight={400}>{product}</Heading>
      <br />
      <Text>
        <b>Vision:</b> {idea}
      </Text>
      <br />
      <Text>
        <b>Mission:</b> {mission}
      </Text>
    </Box>
  );
};

export default CompanyIdeaCard;
