import { useCallback, useState, useEffect } from 'react';
import {
  VStack,
  Text,
  Input,
  Button,
  Spinner,
  Box,
  HStack,
  IconButton,
  Textarea,
  Select,
} from '@chakra-ui/react';
import { AddIcon, CloseIcon } from '@chakra-ui/icons';
import { useCompletion } from '../../../hooks/useGetCompletionLazy';
import { ResponsePayload, Prompt } from '../../../client/backendClient';
import useAnalyticsEventTracker from '../../../hooks/useAnalyticsEventTracker';

export interface StepProps {
  onStepCompleted: (value: ResponsePayload) => void;
}

interface Props {
  onStepCompleted: (value: ResponsePayload) => void;
  sessionId: string;
  initialPrompt?: Prompt;
  type: string;
}

type ListInputProps = {
  maximumInputs: number;
  initialInputs: string[];
  onSubmit: (inputs: string[]) => void;
};

const GenerateSlides = ({ onClick }: { onClick: () => void }) => (
  <Button type="submit" colorScheme="teal" onClick={onClick}>
    Generate Slides
  </Button>
);

export const ListInput = ({
  maximumInputs,
  initialInputs,
  onSubmit,
}: ListInputProps) => {
  const [inputs, setInputs] = useState<string[]>(initialInputs || ['']);

  return (
    <VStack width="100%">
      {inputs.map((input, idx) => (
        <HStack width="100%" key={idx}>
          <Input
            value={input}
            size="sm"
            flexGrow={1}
            onChange={(event) =>
              setInputs((i) => {
                const copy = [...i];
                copy[idx] = event.target.value;
                return copy;
              })
            }
          />
          {inputs.length > 1 ? (
            <IconButton
              aria-label="Delete"
              colorScheme="teal"
              margin="10px"
              icon={<CloseIcon />}
              onClick={() =>
                setInputs((i) => {
                  const copy = [...i];
                  copy.splice(idx, 1);
                  return copy;
                })
              }
            />
          ) : undefined}
        </HStack>
      ))}

      {maximumInputs <= inputs.length ? undefined : (
        <IconButton
          alignSelf="end"
          aria-label="Add"
          colorScheme="teal"
          margin="10px"
          icon={<AddIcon />}
          onClick={() => setInputs((i) => [...i, ''])}
        />
      )}

      <GenerateSlides
        onClick={() => {
          onSubmit(inputs);
          setInputs(initialInputs);
        }}
      />
    </VStack>
  );
};

const TextInput = ({
  placeholder,
  onSubmit,
  value,
  type,
}: {
  placeholder?: string;
  onSubmit: (value: string) => void;
  value?: string;
  type: string;
}) => {
  const [input, setInput] = useState(value || '');

  const assignInputType = () => {
    switch (type) {
      case 'text-area':
        return (
          <Textarea
            value={input}
            onChange={(event) => setInput(event.target.value)}
            placeholder={placeholder || ''}
            size="md"
            autoFocus
          />
        );
      case 'text-select':
        return (
          <Select
            onChange={(event) => setInput(event.target.value)}
            autoFocus
            placeholder="Select option"
          >
            <option value="To Persuade">To Persuade</option>
            <option value="To Inform">To Inform</option>
            <option value="To Inspire">To Inspire</option>
            <option value="To Entertain">To Entertain</option>
          </Select>
        );
      default:
        return (
          <Input
            value={input}
            onChange={(event) => setInput(event.target.value)}
            placeholder={placeholder || ''}
            size="md"
            autoFocus
          />
        );
    }
  };
  return (
    <>
      {assignInputType()}
      <GenerateSlides
        onClick={() => {
          onSubmit(input);
          setInput('');
        }}
      />
    </>
  );
};

const asString = (text: string | string[]) => {
  if (Array.isArray(text)) {
    throw new Error(`Cannot accept an array: ${JSON.stringify(text)} as text`);
  }
  return text;
};

const asStringList = (text: string | string[]) => {
  if (!Array.isArray(text)) {
    throw new Error(`Cannot accept a string: ${text} as an array`);
  }
  return text;
};

const InputComp = ({
  isLoading,
  p,
  onSubmit,
}: {
  isLoading: boolean;
  p?: Prompt;
  onSubmit: (value: string | string[]) => void;
}) => {
  if (isLoading) {
    return <Spinner />;
  }

  if (!p) {
    // If there is no prompt, this *should* be the last enabled prompt so should result in the download slide being shown.
    return null;
  }

  if (p.type === 'text-list') {
    return (
      <ListInput
        initialInputs={p.initialValues ? asStringList(p.initialValues) : []}
        maximumInputs={10}
        onSubmit={onSubmit}
      />
    );
  }

  return (
    <TextInput
      placeholder={p.placeholder}
      type={p.type}
      onSubmit={onSubmit}
      value={p.initialValues && asString(p.initialValues)}
    />
  );
};

export const Step = ({
  onStepCompleted,
  sessionId,
  initialPrompt,
  type,
}: Props) => {
  const [promptStep, setPromptStep] = useState(0);
  const [currentPrompt, setPrompt] = useState<Prompt | undefined>(
    initialPrompt
  );
  const gaEventTracker = useAnalyticsEventTracker(
    `Step ${promptStep || 'no step selected'}`
  );
  const [currentInput, setCurrentInput] = useState<string | null>();

  const onStepCompletedFn = useCallback(
    (data: ResponsePayload) => onStepCompleted(data),
    // TODO: We should wrap in useCallback higher up the component tree (where the function is defined)
    // but that's going to require a refactor to get working...
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const { isInitialLoading, data, error, trigger } = useCompletion(
    type,
    sessionId,
    currentPrompt
  );

  const progressToNextStep = useCallback(
    (d: ResponsePayload, p?: Prompt) => {
      onStepCompletedFn(d);
      setPrompt(d.prompt);
      setPromptStep((step) => step + 1);
      gaEventTracker(
        `completed ${p?.value || 'unknown'} step`,
        currentInput || ''
      );
    },
    [onStepCompletedFn, setPrompt, setPromptStep, gaEventTracker, currentInput]
  );

  const onSubmit = (value: string | string[]) => {
    setCurrentInput(typeof value === 'object' ? value.join('|') : value);
    trigger(value);
  };

  useEffect(() => {
    if (data) {
      progressToNextStep(data, currentPrompt);
    }
    // eslint-disable-next-line
  }, [data, onStepCompletedFn]);

  if (error) {
    console.error(error);
    gaEventTracker('RATE LIMIT ERROR');
    return (
      <Box>
        Oops! We’re experiencing high volume right now 🫠 Please try again later.
      </Box>
    );
  }
  return (
    <VStack as="form" onSubmit={(e) => e.preventDefault()} w="100%">
      <Text css="white-space: pre-wrap;" fontSize="lg" mb="8px">
        {currentPrompt?.value}
      </Text>
      <InputComp
        isLoading={isInitialLoading}
        onSubmit={onSubmit}
        p={currentPrompt}
      />
    </VStack>
  );
};
