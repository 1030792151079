import axios from 'axios';
import { getConfig } from '../helpers/config';

const client = axios.create();

export type Title = {
  colour?: string;
  size?: number;
  value: string;
};

export type TextFormatting = {
  size?: number;
  colour?: string;
};

// TODO: At the moment we're just copying over the types between BE / FE... share the types in a more sane way (code-gen or something else)
export type Text = {
  value: string;
  contentFormatting?: 'bullet-list' | 'numbered-list' | 'headers';
  textFormatting?: {
    size?: number;
    colour?: string;
  };
};

export type SlideFormatting = {
  titleLocation?: 'left' | 'right';
  backgroundColour?: string;
};

// For a predetermined slide generator perhaps the prompt id is hardcoded, for a dynamic one perhaps it's a uuid
export type Prompt = {
  id: string;
  value: string;
  placeholder?: string;
  // If true, will just submit the user input rather than calling the AI Completion with the input
  shouldSubmitUserInput?: boolean;
  type: 'text' | 'text-list' | 'text-area' | 'text-select';
  initialValues?: string | string[];
};

export type RequestPayload = {
  context?: {
    // Which step is this in the process
    stepIndex: number;
    // All prompts & user inputs for this session
    chat: {
      prompt: Prompt;
      userInput: string | string[];
    }[];
  };
  type: string;
  sessionId: string;
};

export type PromptMap = {
  id: string;
  value: string;
  disabled?: boolean;
  shouldDisplay?: boolean;
  // If this is set to true, this particular step will be entirely overwritten by the next response.
  // It's somewhat of a hack to make the title slide work properly despite the fact we return a partially completed slide,
  // then the next response contains the actual value for that slide
  shouldOverwrite?: boolean;
}[];

export type ResponsePayload = {
  prompts: PromptMap;
  prompt?: Prompt;
  slides: {
    title?: Title;
    text: Text;
    slideFormatting?: SlideFormatting;
    slideLabel?: Title;
  }[];
};

const completion = async (
  payload: RequestPayload
): Promise<ResponsePayload> => {
  const { data } = await client.post<ResponsePayload>(
    getConfig().generateSlideUrl,
    payload
  );
  return data;
};

interface PayPayload {
  userId: string;
  email: string;
}

interface PayResponsePayload {
  url: string;
}

const pay = async (payload: PayPayload): Promise<PayResponsePayload> => {
  const { data } = await client.post<PayResponsePayload>(
    getConfig().payUrl,
    payload
  );
  return data;
};

export default completion;

export { pay };
