import { Button, Text, VStack } from '@chakra-ui/react';
import { Topic } from '../../../types/Topic';
import { Form } from '../Form/Form';
import {
  getLoadingMessage,
  isLoadingStatus,
  Status,
} from '../GeneralPurposePoc';
import { TopicsList } from '../TopicsList/TopicsList';

type Props = {
  topics: Topic[] | null;
  setTopics: (topics: Topic[]) => void;
  status: Status;
  regenerateSlides: () => void;
  topic: string;
};

export const PresentationStructureForm = ({
  topics,
  setTopics,
  status,
  regenerateSlides,
  topic,
}: Props) => (
  <Form>
    <VStack flex="1" minH={0} width="100%">
      <>
        <VStack
          as="form"
          onSubmit={(e) => e.preventDefault()}
          p={5}
          color="white"
          borderRadius={30}
          h="fit-content"
          maxW="100%"
        >
          <Text fontSize="lg" mb="8px">
            Your presentation structure
          </Text>

          {topics && (
            <TopicsList
              initialInputs={topics}
              maximumInputs={10}
              onChange={(values) => setTopics(values)}
              disabled={isLoadingStatus(status)}
            />
          )}
        </VStack>

        {topics && (
          <VStack
            as="form"
            onSubmit={(e) => e.preventDefault()}
            p={5}
            color="white"
            borderRadius={30}
            h="fit-content"
            maxW="100%"
          >
            <Button
              type="submit"
              colorScheme="teal"
              onClick={regenerateSlides}
              isLoading={isLoadingStatus(status)}
              loadingText={getLoadingMessage(status)}
              isDisabled={
                topic.length === 0 ||
                topics.length === 0 ||
                topics.every((t) => t.topic.length === 0)
              }
            >
              Re-generate slides
            </Button>
          </VStack>
        )}
      </>
    </VStack>
  </Form>
);
