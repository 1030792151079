/* eslint-disable */
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Image,
  ListItem,
  OrderedList,
  Spinner,
  Text,
  TextProps,
  UnorderedList,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import PPTXGen from 'pptxgenjs';
import { v4 } from 'uuid';
import { useQuery } from '@tanstack/react-query';
import { User } from 'firebase/auth';

import { useAuthState } from 'react-firebase-hooks/auth';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { generateSlides, generateTitleSlide } from './slideUtils';
import SlideContainer from './components/SlideContainer';
import completion, {
  ResponsePayload,
  Title,
  Text as TextData,
  PromptMap,
  Prompt,
} from '../../client/backendClient';
import SlideGenHeader from './components/SlideGenHeader';
import { Step } from './steps/Step';
import { auth, db, incrementUsageCount } from '../../firebase';
import { PowerModeUser } from '../../types/PowerModeUser';
import BuyLifetimeAccess from './components/BuyLifetimeAccess';
import useAnalyticsEventTracker from '../../hooks/useAnalyticsEventTracker';

export const GradientBox = ({ children }: any) => (
  <Box
    minW="100vw"
    minH="100Vh"
    bgGradient="linear(to-tr, #620049, #56004b, #49004c, #3b004d, #29004e, #201057, #141b5f, #002565, #00396f, #00496b, #00555f, #026052)"
  >
    {children}
  </Box>
);

// The group of buttons at the top that signal which step the user is on.
const SectionButtons = ({
  promptMap,
  currentSection,
}: {
  promptMap: PromptMap;
  currentSection: string;
}) => (
  <Flex gap={4} mt={6} ml={12} flexWrap="wrap">
    {promptMap
      .filter((section) => section.shouldDisplay !== false)
      .map((section) => {
        const step = promptMap.findIndex((search) => search.id === section.id);
        const currentStep = promptMap.findIndex(
          (search) => search.id === currentSection
        );

        const isCompleted = currentSection === 'download' || step < currentStep;

        const editingStep = currentStep === step;
        // TODO: refactor these variants into the theme and finish sorting the colors
        return (
          <Button
            key={section.id}
            variant={
              isCompleted ? 'sectionIndicatorCompleted' : 'sectionIndicator'
            }
            border={editingStep ? '2px solid rgba(49, 151, 96, 0.81)' : 'none'}
            size="sm"
          >
            {section.value}
          </Button>
        );
      })}
  </Flex>
);

// A DOM that simulates what the generated slide will look like.
export const SlidePreview = ({
  slides,
  deckType,
}: {
  slides: ResponsePayload[];
  deckType: string;
}) => {

  return (
    <Flex direction="column" gap={4} width="100%">
      {/* <TitleSlidePreview slideData={slides[0].slides} deckType={deckType} /> */}
      {slides.slice(1).map((slideDatum, idx) => (
        <GeneralSlidePreview data={slideDatum.slides} key={idx} />
      ))}
    </Flex>
  );
};

export const TitleSlidePreview = ({
  slideData,
  deckType,
}: {
  slideData: ResponsePayload['slides'];
  deckType: string;
}) => {

  const titleData: ResponsePayload['slides'] = slideData;

  if (titleData.length !== 1) {
    throw new Error(
      'Got more than one slide for the title slide, this is unexpected...'
    );
  }

  const slide = titleData[0];
  return (
    <SlideContainer
      justify="center"
      slideData={{
        ...slide,
        slideFormatting: { backgroundColour: '#000000' },
      }}
    >
      <HStack height="100%" width="100%">
        <Box height="100%" width="50%" />
        <Box height="100%" width="50%">
          <Image src="/title_slide_image.png" />
        </Box>
      </HStack>
      <VStack position="absolute" bottom="75px" left="75px">
        <Heading textColor="#FFFFFF">{slide.text.value}</Heading>
        // we only show the pitch deck text for the start up pitch
        {deckType === 'start-up-pitch' && (
          <Text textColor="#FFFFFF">Pitch Deck #1</Text>
        )}
      </VStack>
      <HStack position="absolute" bottom="10px" right="10px">
        <Text textColor="#FFFFFF" fontSize="16px">
          Made with GPT-3.5 and
        </Text>
        <Image src="/LogoWhite.svg" h={10} marginLeft="0px" />
      </HStack>
    </SlideContainer>
  );
};

export const GeneralSlidePreview = ({
  data,
}: {
  data: ResponsePayload['slides'];
}) => (
  <>
    {data.map((datum, idx) => (
      <SlideContainer
        slideData={datum}
        key={idx}
        heading={
          datum.slideLabel && (
            <Box position="absolute" top="10px" left="10px">
              <TitleContent title={datum.slideLabel} />
            </Box>
          )
        }
      >
        {datum.title && (
          <Flex
            width="100%"
            justifyContent={
              datum.slideFormatting?.titleLocation === 'right'
                ? 'right'
                : 'left'
            }
          >
            <TitleContent title={datum.title} />
          </Flex>
        )}
        <TitleAndContentSeperator />
        <Box
          overflowY="hidden"
          overflowX="hidden"
          width="100%"
          marginLeft="10px"
          marginRight="10px"
          overflowWrap="break-word"
        >
          <SlideContent text={datum.text} />
        </Box>
      </SlideContainer>
    ))}
  </>
);

// The floating container which guides the user through each of the prompts.
const PromptContainer = ({ children }: any) => (
  <Box
    p={5}
    bg="blackAlpha.500"
    color="white"
    borderRadius={30}
    h="fit-content"
    maxW="100%"
  >
    {children}
  </Box>
);

const TitleContent = ({ title }: { title: Title }) => (
  <Text color={title.colour || '#000000'} fontSize={`${title.size || '30'}px`}>
    {title.value}
  </Text>
);

const SlideContent = ({ text }: { text: TextData }) => {
  if (text.contentFormatting === 'bullet-list') {
    return <SlideList text={text} />;
  }

  if (text.contentFormatting === 'numbered-list') {
    return <SlideList text={text} ordered />;
  }

  if (text.contentFormatting === 'headers') {
    return <SlideHeaderContent text={text} />;
  }

  return <TextWithNewLines text={text} />;
};

export const getListFormattedLines = (text: string) => {
  const lines = getTextLines(text);
  return lines
    .map((line) => {
      const lineTrimmed = line.trim();

      // Strip out '-'
      if (lineTrimmed.length && lineTrimmed[0] === '-') {
        return lineTrimmed.substring(1);
      }

      return lineTrimmed;
    })
    .filter((line) => !!line);
};

interface HeaderTextPair {
  header: string;
  text: string;
}

export const getHeadersAndText = (text: TextData): HeaderTextPair[] =>
  JSON.parse(text.value) as HeaderTextPair[];

const SlideHeaderContent = ({ text }: { text: TextData }) => {
  const headerTextPairs = getHeadersAndText(text);

  return (
    <VStack height="100%" width="100%">
      <HStack width="100%">
        {headerTextPairs.map(({ header }, idx) => (
          <Heading
            size="md"
            minHeight="30px"
            textAlign="center"
            flex={1}
            key={idx}
          >
            {header}
          </Heading>
        ))}
      </HStack>
      <Flex height="5px" width="100%">
        {headerTextPairs.map((_, idx) => (
          <Box flex={1} key={idx}>
            <Box
              marginTop="2px"
              marginBottom="2px"
              borderColor="#000"
              borderStyle="solid"
              borderWidth="0.5px"
              width="75%"
              margin="auto"
            />
          </Box>
        ))}
      </Flex>
      <HStack width="100%" textAlign="center" alignItems="start">
        {headerTextPairs.map(({ text: t }, idx) => (
          <SlideText
            key={idx}
            flex={1}
            padding="5px"
            text={{ ...text, value: t }}
          />
        ))}
      </HStack>
    </VStack>
  );
};

const SlideText = (props: TextProps & { text: TextData }) => {
  const { text } = props;

  return (
    <Text
      color={text.textFormatting?.colour || '#000000'}
      fontSize={`${text.textFormatting?.size || '20'}px`}
      {...props}
    >
      {text.value}
    </Text>
  );
};

const TitleAndContentSeperator = () => <Box w="100%" h="30px" />;

const getTextLines = (text: string): string[] => text.split('\n');

const SlideList = ({
  text,
  ordered,
}: {
  text: TextData;
  ordered?: boolean;
}) => {
  const formattedLines = getListFormattedLines(text.value);
  const listItems = formattedLines.map((line, idx) => (
    <ListItem key={idx} wordBreak="break-word">
      <SlideText key={idx} text={{ ...text, value: line }} />
    </ListItem>
  ));

  if (ordered) {
    return (
      <OrderedList alignContent="left" marginInlineStart="2em">
        {listItems}
      </OrderedList>
    );
  }

  return (
    <UnorderedList alignContent="left" marginInlineStart="2em">
      {listItems}
    </UnorderedList>
  );
};

const TextWithNewLines = ({ text }: { text: TextData }) => {
  const lines = getTextLines(text.value);
  return (
    <>
      {lines.map((line, idx) => (
        <SlideText key={idx} text={{ ...text, value: line }} />
      ))}
    </>
  );
};

const DownloadSlidesPrompt = ({
  slideData,
  deckType,
}: {
  slideData: ResponsePayload[];
  deckType: string;
}) => {
  const gaEventTracker = useAnalyticsEventTracker('Download');
  return (
    <Flex direction="column" gap={8}>
      <Text fontSize="32px">Your slides are ready 🎉</Text>
      <Button
        onClick={() => {
          const deck = new PPTXGen();

          generateTitleSlide(deck, slideData[0].slides, deckType);
          slideData.slice(1).map(({ slides }) => generateSlides(deck, slides));

          deck.writeFile({
            fileName: `pitch.pptx`,
          });

          gaEventTracker('Clicked Download Slides');
        }}
        colorScheme="teal"
      >
        Download Slides 🚀
      </Button>
    </Flex>
  );
};

const PromptComp = ({
  section,
  setNextStep,
  slideData,
  sessionId,
  initialPrompt,
  type,
}: {
  section: string;
  setNextStep: (data: ResponsePayload) => void;
  slideData: ResponsePayload[];
  sessionId: string;
  initialPrompt?: Prompt;
  type: string;
}) => {
  if (section === 'download') {
    incrementUsageCount();
    return <DownloadSlidesPrompt slideData={slideData} deckType={type} />;
  }

  return (
    <Step
      onStepCompleted={setNextStep}
      sessionId={sessionId}
      initialPrompt={initialPrompt}
      type={type}
    />
  );
};

const updateSection = (
  sData: ResponsePayload[],
  setCurrentSection: (id: string) => void,
  promptMap: PromptMap
) => {
  //TODO: the stepper logic needs to be refactored, this is a bit of a hack ontop of something that wasn't great!
  const currentIdx =
    sData.length === 1
      ? sData[0].slides[0].text.value === '[Your deck title]' ||
        sData[0].slides[0].text.value === '[Your Company Name]'
        ? 0
        : 1
      : sData.length;
  const nextSection = promptMap[currentIdx];
  setCurrentSection(nextSection.id);
};

// Creates a sessionId on mount for this deck generation, mainly for identifying a single user session on the backend
const useSessionId = (): string => {
  const [sessionId, setSessionId] = useState<string>('');
  useEffect(() => setSessionId(v4()), []);
  return sessionId;
};

const getCurrentPromptMap = (responses: ResponsePayload[]) =>
  responses[responses.length - 1].prompts;

const useHandleAPICalls = (type: string, sessionId: string) => {
  const [slideData, setSlideData] = useState<ResponsePayload[]>([]);
  const {
    isLoading,
    error,
    data: initialResponse,
  } = useQuery({
    queryKey: ['completion-initialCall', type],
    queryFn: () =>
      completion({
        type,
        sessionId,
      }),
    refetchOnWindowFocus: false,
  });
  const responses = useMemo(() => {
    const values = [
      ...(initialResponse ? [initialResponse] : []),
      ...slideData,
    ];

    if (!values.length) {
      return [];
    }

    const currentPromptMap = getCurrentPromptMap(values);

    // look up in the prompt map if each value should be overridden and if there is a next slide, go ahead and overwrite it
    const filteredValues = values.filter((value, idx) => {
      const promptMapEntry = currentPromptMap.find(
        ({ id }) => id === value.prompt?.id
      );
      if (!promptMapEntry) {
        // we couldn't find this prompt in our prompt map, so let's just include it
        return true;
      }
      // if we should override it and we already have the next response, we should filter it out
      return !(
        promptMapEntry.shouldOverwrite === true && values.length - 1 > idx
      );
    });

    return filteredValues;
  }, [initialResponse, slideData]);
  return { responses, isLoading, error, setSlideData };
};

const DeckContent = ({ type }: { type: string }) => {
  const [currentSection, setCurrentSection] = useState<string>('');
  const sessionId = useSessionId();
  const { responses, isLoading, error, setSlideData } = useHandleAPICalls(
    type,
    sessionId
  );

  useEffect(() => {
    if (isLoading) {
      // can't load the section if the prompts aren't ready
      return;
    }

    // We'll use the prompt map to determine if we're actually finished with this deck and therefore should show the download slides step
    const promptMap = getCurrentPromptMap(responses);
    const findIndexResponse = promptMap.findIndex((prompt) => prompt.disabled);
    // If we didn't find the first disabled, then the end should be the last prompt
    const firstDisabledPromptIdx =
      findIndexResponse === -1 ? promptMap.length : findIndexResponse;

    if (firstDisabledPromptIdx === responses.length) {
      setCurrentSection('download');
    } else {
      updateSection(
        responses,
        setCurrentSection,
        getCurrentPromptMap(responses)
      );
    }
    // slide data updates each time the prompt progresses
  }, [responses, isLoading]);

  if (isLoading) {
    return <Spinner color="#FFFFFF" margin="20px" />;
  }

  if (error) {
    console.error(error);
    return (
      <Box margin="20px" textColor="#FFFFFF">
        Oops! We’re experiencing high volume right now 🫠 Please try again later.
      </Box>
    );
  }

  return (
    <>
      <SectionButtons
        promptMap={getCurrentPromptMap(responses)}
        currentSection={currentSection}
      />
      <Wrap pt={50} p={10} spacing={8} minW="250px">
        <WrapItem minW="250px" maxW="400px">
          <PromptContainer>
            <PromptComp
              section={currentSection}
              setNextStep={(data) => setSlideData((s) => [...s, data])}
              slideData={responses}
              sessionId={sessionId}
              // We should always have at least 1 response by this point since we have
              // requested the initial state
              initialPrompt={responses[0].prompt}
              type={type}
            />
          </PromptContainer>
        </WrapItem>
        <WrapItem maxWidth="100%" m={0}>
          <SlidePreview slides={responses} deckType={type} />
        </WrapItem>
      </Wrap>
    </>
  );
};

const fetchUser = async (user: User) => {
  try {
    const q = query(collection(db, 'users'), where('uid', '==', user.uid));
    const doc = await getDocs(q);
    const data = doc.docs[0].data();

    return {
      usage: data.usage,
      name: data.name,
      isPaidUser: data.isPaidUser,
    };
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const Deck = ({ type }: { type: string }) => {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  const [powerModeUser, setPowerModeUser] = useState<PowerModeUser | null>(
    null
  );
  const [needsToPay, setNeedsToPay] = useState(false);

  useEffect(() => {
    if (user) {
      (async () => {
        const { name, usage, isPaidUser } = await fetchUser(user);

        setPowerModeUser({
          name,
          usageCount: usage,
          isPaidUser,
        });

        if (usage >= 3 && !isPaidUser) {
          setNeedsToPay(true);
        }
      })();
    } else if (!loading) {
      navigate('/');
    }
  }, [user, loading]);

  return (
    <GradientBox>
      {powerModeUser && (
        <>
          <SlideGenHeader user={powerModeUser} />
          {needsToPay ? <BuyLifetimeAccess /> : <DeckContent type={type} />}
        </>
      )}
    </GradientBox>
  );
};
