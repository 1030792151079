import { Text, VStack } from '@chakra-ui/react';
import ReactScrollIntoViewIfNeeded from 'react-scroll-into-view-if-needed';
import { Slide } from '../../../types/Slide';
import { SlideType } from '../../../types/SlideType';
import { BulletPointsContent } from './BulletPointsContent';
import { TextContent } from './TextContent';

interface Props {
  slides: Partial<Slide>[];
}

export const SlidePreview: React.FC<Props> = ({ slides }) => (
  <VStack minHeight={0} flex="1" overflowY="auto">
    {slides.map((slide, index) => (
      <ReactScrollIntoViewIfNeeded key={index}>
        <VStack bg="#fff" w={600} height={400} spacing={10} p={10}>
          <Text fontSize={40}>{slide.title}</Text>
          {slide.type === SlideType.Text ? (
            <TextContent content={slide.content as string} />
          ) : (
            <BulletPointsContent content={slide.content as string[]} />
          )}
        </VStack>
      </ReactScrollIntoViewIfNeeded>
    ))}
  </VStack>
);
